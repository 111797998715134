<template>
	<div>
		<b-media class="mb-2">
			<template #aside>
				<b-avatar ref="previewEl" :src="userObject.avatar" :text="avatarText(null)" size="90px" rounded />
			</template>
			<h4 class="mb-1">
				{{ $route.params.user_id ? "Actualizar usuario" : "Crear usuario" }}
			</h4>
			<div class="d-flex flex-wrap">
				<b-button variant="primary" @click="$refs.fileInput.click()">
					<input ref="fileInput" type="file" @input="pickFile" class="d-none" />
					<span class="d-none d-sm-inline">Actualizar</span>
					<feather-icon icon="EditIcon" class="d-inline d-sm-none" />
				</b-button>
				<b-button variant="outline-secondary" class="ml-1" @click="userObject.avatar = ''">
					<span class="d-none d-sm-inline">Eliminar</span>
					<feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
				</b-button>
			</div>
		</b-media>

		<validation-observer ref="form">
			<b-form>
				<b-row>
					<b-col lg="7">
						<b-card>
							<h2 class="mb-2">Información personal</h2>

							<b-row>
								<b-col md="12" lg="6">
									<b-form-group label="Tipo de documento" label-for="document">
										<v-select
											id="document"
											v-model="userObject.document_type_id"
											:options="configurations.document_types"
											:clearable="false"
											class="w-100"
											label="name"
											:disabled="true"
											:reduce="(option) => option.id"
										/>
									</b-form-group>
								</b-col>

								<b-col md="12" lg="6">
									<b-form-group label-for="dni">
										<template #label>
											<span>DNI</span>
											<span class="required-asterisk">(*)</span>
										</template>
										<validation-provider rules="required|dni" v-slot="{ errors }">
											<b-form-input
												id="dni"
												v-model="userObject.document_number"
												v-mask="'#########'"
												:class="
													errors[0]
														? 'is-invalid'
														: !errors[0] && userObject.document_number
														? 'is-valid'
														: ''
												"
												:maxLength="8"
												:disabled="
													$route.params.user_id &&
													userObject.personal_id !== null &&
													userObject.first_name &&
													userObject.last_name
														? true
														: false
												"
												@keyup.enter="getClientByDni()"
												:placeholder="'Ingresar número de DNI...'"
											/>
											<span class="error-span">{{ errors[0] }}</span>
										</validation-provider>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col md="12" lg="6">
									<b-form-group label-for="first_name">
										<template #label>
											<span>Nombre</span>
											<span class="required-asterisk">(*)</span>
										</template>
										<validation-provider rules="required" v-slot="{ errors, valid }">
											<b-form-input
												id="first_name"
												v-model="userObject.first_name"
												:state="errors[0] ? false : valid ? true : null"
												:maxLength="50"
												:disabled="true"
												:placeholder="'Ingresar un nombre...'"
											/>
											<span class="error-span">{{ errors[0] }}</span>
										</validation-provider>
									</b-form-group>
								</b-col>

								<b-col md="12" lg="6">
									<b-form-group label="Segundo nombre" label-for="middle_name">
										<b-form-input
											id="middle_name"
											v-model="userObject.middle_name"
											:maxLength="50"
											:disabled="true"
											:placeholder="'Ingresar un nombre...'"
										/>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row>
								<b-col md="12" lg="6">
									<b-form-group label-for="last_name">
										<template #label>
											<span>Apellido</span>
											<span class="required-asterisk">(*)</span>
										</template>
										<validation-provider rules="required" v-slot="{ errors, valid }">
											<b-form-input
												id="last_name"
												v-model="userObject.last_name"
												:state="errors[0] ? false : valid ? true : null"
												:maxLength="50"
												:disabled="true"
												:placeholder="'Ingresar un apellido...'"
											/>
											<span class="error-span">{{ errors[0] }}</span>
										</validation-provider>
									</b-form-group>
								</b-col>

								<b-col md="12" lg="6">
									<b-form-group label-for="second_last_name">
										<template #label>
											<span>Segundo apellido</span>
											<span class="required-asterisk">(*)</span>
										</template>
										<validation-provider rules="required" v-slot="{ errors, valid }">
											<b-form-input
												id="second_last_name"
												v-model="userObject.second_last_name"
												:state="errors[0] ? false : valid ? true : null"
												:maxLength="50"
												:disabled="true"
												:placeholder="'Ingresar un apellido...'"
											/>
											<span class="error-span">{{ errors[0] }}</span>
										</validation-provider>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row>
								<b-col md="12" lg="6">
									<b-form-group label-for="dob">
										<template #label>
											<span>Fecha de nacimiento</span>
											<span class="required-asterisk">(*)</span>
										</template>
										<validation-provider rules="required" v-slot="{ errors, valid }">
											<b-form-datepicker
												id="dob"
												class=""
												locale="en-US"
												placeholder="Seleccionar una fecha..."
												:date-format-options="{
													month: 'numeric',
													day: 'numeric',
													year: 'numeric',
												}"
												v-model="userObject.dob"
												:max="maxDate"
											/>
											<span class="error-span">{{ errors[0] }}</span>
										</validation-provider>
									</b-form-group>
								</b-col>
								<b-col md="12" lg="6">
									<b-form-group label-for="country_id">
										<template #label>
											<span>País de nacimiento</span>
											<span class="required-asterisk">(*)</span>
										</template>
										<validation-provider rules="required" v-slot="{ errors, valid }">
											<v-select
												id="country_id"
												v-model="userObject.country_id"
												:options="configurations.countries"
												:clearable="false"
												class="w-100"
												label="name"
												:reduce="(option) => option.id"
												:placeholder="'Seleccionar una nacionalidad...'"
											/>
											<span class="error-span">{{ errors[0] }}</span>
										</validation-provider>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row>
								<b-col cols="12" md="12">
									<b-form-group label-for="gender">
										<template #label>
											<span>Género</span>
											<span class="required-asterisk">(*)</span>
										</template>
										<validation-provider rules="required" v-slot="{ errors, valid }">
											<b-form-radio-group
												id="gender"
												v-model="userObject.gender_id"
												:options="configurations.genders"
												class=""
												value-field="id"
												text-field="name"
												disabled-field="notEnabled"
											></b-form-radio-group>
											<span class="error-span">{{ errors[0] }}</span>
										</validation-provider>
									</b-form-group>
								</b-col>
							</b-row>
						</b-card>

						<b-card>
							<h2 class="mb-2">Información de contacto</h2>

							<b-row>
								<b-col md="12" lg="8">
									<b-form-group label-for="address">
										<template #label>
											<span>Dirección</span>
											<span class="required-asterisk">(*)</span>
										</template>
										<validation-provider rules="required" v-slot="{ errors, valid }">
											<b-form-input
												id="address"
												v-model="userObject.address"
												:state="errors[0] ? false : valid ? true : null"
												:maxLength="50"
												:placeholder="'Ingresar una dirección...'"
											/>
											<span class="error-span">{{ errors[0] }}</span>
										</validation-provider>
									</b-form-group>
								</b-col>

								<b-col md="12" lg="4">
									<b-form-group label-for="phone_number">
										<template #label>
											<span>Teléfono</span>
											<span class="required-asterisk">(*)</span>
										</template>
										<validation-provider rules="tel" v-slot="{ errors }">
											<b-form-input
												id="phone_number"
												v-model="userObject.phone_number"
												v-mask="'#########'"
												:class="
													errors[0]
														? 'is-invalid'
														: !errors[0] && userObject.phone_number
														? 'is-valid'
														: ''
												"
												:maxLength="50"
												:placeholder="'Ingresar un número de teléfono...'"
											/>
											<span class="error-span">{{ errors[0] }}</span>
										</validation-provider>
									</b-form-group>
								</b-col>
							</b-row>
						</b-card>
					</b-col>
					<b-col lg="5">
						<b-card>
							<h2 class="mb-2">Datos de acceso</h2>
							<b-form-group label-for="email">
								<template #label>
									<span>Correo</span>
									<span class="required-asterisk">(*)</span>
								</template>
								<validation-provider rules="required|email" v-slot="{ errors, valid }">
									<b-form-input
										id="email"
										type="email"
										v-model="userObject.email"
										:state="errors[0] ? false : valid ? true : null"
										:maxLength="50"
										:disabled="$route.params.user_id ? true : false"
									/>
									<span class="error-span">{{ errors[0] }}</span>
								</validation-provider>
							</b-form-group>
							<b-form-group label-for="password">
								<template #label>
									<span>Contraseña</span>
									<span class="required-asterisk">(*)</span>
								</template>
								<validation-provider
									:rules="!$route.params.user_id ? 'required' : ''"
									v-slot="{ errors, valid }"
								>
									<b-form-input
										id="password"
										v-model="userObject.password"
										type="password"
										:state="errors[0] ? false : valid ? true : null"
										:maxLength="50"
									/>
									<span class="error-span">{{ errors[0] }}</span>
								</validation-provider>
							</b-form-group>
							<b-row class="mb-2">
								<b-col md="12" lg="6" v-if="isAdmin">
									<validation-provider rules="required" v-slot="{ errors, valid }">
										<b-form-group label-for="rol" class="mb-0">
											<template #label>
												<span>Rol</span>
												<span class="required-asterisk">(*)</span>
											</template>
											<b-form-radio-group
												id="rol"
												v-model="rol"
												:options="optionsRoles"
												class="mb-0"
												value-field="id"
												text-field="name"
												disabled-field="notEnabled"
											></b-form-radio-group>
										</b-form-group>
										<span class="error-span">{{ errors[0] }}</span>
									</validation-provider>
								</b-col>

								<b-col md="12" lg="6" v-if="rol == 2 && isAdmin">
									<b-form-group label="Rol(es)">
										<v-select
											taggable
											multiple
											v-model="userObject.roles"
											:clearable="false"
											label="name"
											:options="arrayRoles"
											:reduce="(option) => option.id"
										/>
									</b-form-group>
								</b-col>

								<b-col md="12" lg="6" v-if="rol == 2 && isAdmin && userObject.roles.length > 0">
									<b-form-group label="Cargo">
										<v-select
											taggable
											v-model="userObject.privilege_id"
											:clearable="false"
											label="name"
											:options="arrayCargo"
											:reduce="(option) => option.id"
										/>
									</b-form-group>
								</b-col>

								<b-col md="12" lg="6" v-if="isAdmin && $route.params.user_id">
									<b-form-group label-for="status">
										<template #label>
											<span>Estado</span>
											<span class="required-asterisk">(*)</span>
										</template>
										<b-form-radio-group
											id="status"
											v-model="userObject.status"
											:options="optionsStatus"
											class="mb-0"
											value-field="id"
											text-field="name"
											disabled-field="notEnabled"
										></b-form-radio-group>
									</b-form-group>
								</b-col>
							</b-row>
						</b-card>

						<b-card>
							<h2 class="mb-2">Información laboral</h2>

							<b-row>
								<b-col md="12" lg="12" xl="6">
									<b-form-group label-for="date_joined">
										<template #label>
											<span>Fecha de ingreso a la empresa</span>
											<span class="required-asterisk">(*)</span>
										</template>
										<validation-provider rules="required" v-slot="{ errors, valid }">
											<b-form-datepicker
												id="date_joined"
												class=""
												locale="en-US"
												placeholder="Seleccionar..."
												:date-format-options="{
													month: 'numeric',
													day: 'numeric',
													year: 'numeric',
												}"
												v-model="userObject.date_joined"
											/>
											<span class="error-span">{{ errors[0] }}</span>
										</validation-provider>
									</b-form-group>
								</b-col>

								<b-col md="12" lg="12" xl="6">
									<b-form-group label-for="salary">
										<template #label>
											<span>Salario (S/)</span>
											<span class="required-asterisk">(*)</span>
										</template>
										<validation-provider rules="required" v-slot="{ errors, valid }">
											<money
												id="salary"
												v-model="userObject.salary"
												v-bind="{
													decimal: '.',
													thousands: ',',
													prefix: 'S/ ',
													precision: 2,
													masked: false,
												}"
												class="form-control"
											></money>
											<span class="error-span">{{ errors[0] }}</span>
										</validation-provider>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row class="mb-2">
								<b-col md="12" lg="12" xl="6">
									<b-form-group label="Fecha de pago mensual" label-for="pay_day">
										<div
											style="
												display: flex;
												align-items: center;
												justify-content: flex-start;
												gap: 0.35rem;
												white-space: nowrap;
											"
										>
											<span>Todos los</span>
											<v-select
												id="pay_day"
												v-model="pay_day"
												:options="days"
												:clearable="false"
												label="name"
												:disabled="true"
												style="width: 90px"
											/>
											<span>de cada mes.</span>
										</div>
									</b-form-group>
								</b-col>
							</b-row>
						</b-card>
					</b-col>
				</b-row>

				<b-row>
					<b-col cols="12" md="12">
						<b-card>
							<div class="d-flex justify-content-between align-items-center">
								<h2 class="mb-2">Información bancaria</h2>
								<b-button variant="success" class="btn btn-icon btn-primary" @click="addRow">
									<feather-icon class="text-white cursor-pointer" icon="PlusIcon" size="15" />
								</b-button>
							</div>

							<b-row v-for="(bank, index) in userObject.bank_accounts" :key="index">
								<b-col xl="3">
									<b-form-group label="Banco" label-for="bank">
										<template #label>
											<span>Banco</span>
											<span class="required-asterisk">(*)</span>
										</template>
										<validation-provider rules="required" v-slot="{ errors, valid }">
											<v-select
												id="bank"
												v-model="bank.bank_id"
												:options="configurations.banks"
												:clearable="false"
												class="w-100"
												label="name"
												:reduce="(option) => option.id"
												:placeholder="'Seleccionar una entidad bancaria...'"
											/>
											<span class="error-span">{{ errors[0] }}</span>
										</validation-provider>
									</b-form-group>
								</b-col>

								<b-col xl="4">
									<b-form-group label="Numero de cuenta bancaria" label-for="account_number">
										<template #label v-if="bank.bank_id === 1">
											<span>Numero de cuenta bancaria</span>
											<span class="required-asterisk">(*)</span>
										</template>
										<validation-provider
											:rules="bank.bank_id !== 1 ? '' : 'required'"
											v-slot="{ errors, valid }"
										>
											<b-form-input
												type="number"
												id="account_number"
												v-model="bank.account_number"
												:maxLength="50"
												:placeholder="'Ingresar cuenta bancaria...'"
											/>
											<span class="error-span">{{ errors[0] }}</span>
										</validation-provider>
									</b-form-group>
								</b-col>

								<b-col xl="4">
									<b-form-group label="CCI" label-for="cci">
										<template #label v-if="bank.bank_id !== 1">
											<span>CCI</span>
											<span class="required-asterisk">(*)</span>
										</template>
										<validation-provider
											:rules="bank.bank_id === 1 ? '' : 'required'"
											v-slot="{ errors, valid }"
										>
											<b-form-input
												type="number"
												id="cci"
												v-model="bank.cci"
												:maxLength="10"
												:placeholder="'Ingresar cuenta interbancaria...'"
											/>
											<span class="error-span">{{ errors[0] }}</span>
										</validation-provider>
									</b-form-group>
								</b-col>

								<b-col xl="1">
									<div
										class="d-flex align-items-center justify-content-end h-100"
										:class="userObject.bank_accounts.length === 1 ? 'disabled-btn' : ''"
									>
										<b-button
											variant="danger"
											class="btn btn-icon btn-primary"
											@click="deleteRow(index)"
										>
											<feather-icon size="15" icon="XIcon" class="text-white cursor-pointer" />
										</b-button>
									</div>
								</b-col>
							</b-row>
						</b-card>
					</b-col>
				</b-row>
			</b-form>

			<b-button
				variant="primary"
				class="mb-1 mb-sm-0 mr-0 mr-sm-1"
				:block="$store.getters['app/currentBreakPoint'] === 'xs'"
				@click="onSubmit"
			>
				Guardar
			</b-button>

			<b-button
				variant="outline-secondary"
				type="reset"
				:block="$store.getters['app/currentBreakPoint'] === 'xs'"
				@click="resetUserObject"
				v-if="!$route.params.user_id"
			>
				Resetear
			</b-button>
		</validation-observer>
	</div>
</template>

<script>
import { avatarText } from "@core/utils/filter"
import vSelect from "vue-select"
import { mask } from "vue-the-mask"
import commonsService from "@/services/commons.service"
import usersService from "@/services/users.service"
import store from "@/store"
import { Money } from "v-money"

export default {
	async mounted() {
		await this.getRoles()
		await this.getCargosInit()
		if (this.$route.params.user_id) {
			await this.getUserById()
		}
	},
	async created() {
		await this.getConfigurations()
	},
	computed: {
		layout() {
			return this.$store.getters["appConfig/layout"]
		},
		roles_comp() {
			if (this.currentUser) {
				return this.currentUser.role
			} else {
				return []
			}
		},
		maxDate() {
			const today = new Date()
			// Restamos 15 años a la fecha actual
			const maxDate = new Date(today.setFullYear(today.getFullYear() - 15))
			// Formateamos la fecha en el formato "YYYY-MM-DD"
			return maxDate.toISOString().split("T")[0]
		},
	},
	directives: { mask },
	data() {
		return {
			optionsRoles: [
				{ id: 1, name: "Dueño" },
				{ id: 2, name: "Otro" },
			],
			optionsStatus: [
				{ id: 1, name: "Activo" },
				{ id: 0, name: "Inactivo" },
			],

			arrayRoles: [],
			arrayCargo: [{ id: null, name: "Sin cargo" }],
			rol: null,
			userObject: {
				personal_id: null,
				iduser: this.$route.params.user_id,
				avatar: "",
				document_type_id: 1,
				document_number: null,
				dob: null,
				first_name: null,
				middle_name: null,
				last_name: null,
				second_last_name: null,
				address: null,
				phone_number: null,
				email: null,
				password: null,
				roles: [],
				status: 1,
				file_name: null,
				original_avatar: null,
				privilege_id: null,
				country_id: 178,
				gender_id: null,
				date_joined: null,
				salary: null,
				pay_day: null,
				bank_accounts: [
					{
						bank_id: null,
						account_number: null,
						cci: null,
					},
				],
			},
			days: [
				1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
				29, 30,
			],
			pay_day: 5,
			configurations: {},
		}
	},
	components: {
		vSelect,
		Money,
	},
	methods: {
		addRow() {
			this.userObject.bank_accounts.push({
				bank_id: null,
				account_number: null,
				cci: null,
			})
		},
		deleteRow(index) {
			this.userObject.bank_accounts.splice(index, 1)
		},
		avatarText: function () {
			return avatarText()
		},
		getRoles() {
			axios.post("/api/get-role").then((res) => {
				this.arrayRoles = res.data
			})
		},
		async getCargosInit() {
			const { data } = await commonsService.getCargos()
			this.arrayCargo = [...this.arrayCargo, ...data]
		},
		pickFile() {
			let input = this.$refs.fileInput
			let file = input.files
			if (file && file[0]) {
				let reader = new FileReader()
				reader.onload = (e) => {
					this.userObject.avatar = e.target.result
				}
				reader.readAsDataURL(file[0])
				this.$emit("input", file[0])
				this.userObject.file_name = file[0].name
			}
		},
		async getClientByDni() {
			if (this.userObject.document_number.length < 8) {
				this.showToast(
					"warning",
					"top-right",
					"Advertencia",
					"WarningIcon",
					"El número de DNI debe tener 8 dígitos"
				)
			}

			this.isPreloading()

			try {
				const data = await usersService.getClientByDni(this.userObject.document_number)
				if (data.data.success === false) {
					this.showToast("warning", "top-right", "Advertencia", "WarningIcon", data.data.message)
					return
				} else {
					const names = data.data.data.nombres.split(" ")

					this.userObject.first_name = names[0]
					this.userObject.middle_name = names[1]
					this.userObject.last_name = data.data.data.apellido_paterno
					this.userObject.second_last_name = data.data.data.apellido_materno
				}
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async getUserById() {
			this.isPreloading()

			try {
				const res = await usersService.getUserById(this.$route.params.user_id)
				this.userObject = res.data

				if (this.userObject.country_id === null) {
					this.userObject.country_id = 178
				}

				if (this.userObject.bank_accounts.length === 0) {
					this.userObject.bank_accounts.push({
						bank_id: null,
						account_number: null,
						cci: null,
					})
				}
				const result = this.userObject.roles.filter((rol) => rol != 1)
				if (result.length > 0) {
					this.rol = 2
				} else {
					this.rol = 1
				}
				this.userObject = res.data
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async getConfigurations() {
			this.isPreloading()

			try {
				const { data } = await usersService.getConfigurations()
				this.configurations = data
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async onSubmit() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return

			this.isPreloading()
			const banks = this.userObject.bank_accounts.map((b) => {
				return {
					bank_id: b.bank_id,
					account_number: b.account_number,
					cci: b.cci,
				}
			})

			const body = {
				personal_id: this.userObject.personal_id,
				document_type_id: 1,
				document_number: this.userObject.document_number,
				first_name: this.userObject.first_name,
				middle_name: this.userObject.middle_name,
				last_name: this.userObject.last_name,
				second_last_name: this.userObject.second_last_name,
				dob: this.userObject.dob,
				gender_id: this.userObject.gender_id,
				country_id: this.userObject.country_id,
				address: this.userObject.address,
				phone_number: this.userObject.phone_number,
				file_name: this.userObject.file_name ? this.userObject.file_name : null,
				file_image: this.userObject.avatar,
				access: {
					email: this.userObject.email,
					password: this.userObject.password,
					roles: this.$route.params.user_id ? this.userObject.roles : this.userObject.roles,
					privilege_id: this.userObject.privilege_id ? this.userObject.privilege_id : null,
				},
				work_info: {
					date_joined: this.userObject.date_joined,
					salary: this.userObject.salary,
					pay_day: this.pay_day,
				},
				bank_accounts: banks,
				status: this.userObject.status,
			}

			try {
				const { data } = this.$route.params.user_id
					? await usersService.updateUser(this.userObject.id, body)
					: await usersService.registerUser(body)

				this.showSuccessToast(data.message)
				let userData = JSON.parse(localStorage.getItem("vuex"))
				userData.user.fields_completed = 1
				userData.user.name = this.userObject.first_name
				userData.user.last_name = this.userObject.last_name
				localStorage.setItem("vuex", JSON.stringify(userData))
				store.commit("auth/SET_CURRENT_USER", userData)
				if (!this.isAdmin) {
					this.$router.push("/menu")
				}
				if (this.isAdmin) {
					this.$router.push("/users-route")
				}
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		resetUserObject() {
			this.userObject.avatar = ""
			this.userObject.document_type_id = 1
			this.userObject.document_number = null
			this.userObject.dob = null
			this.userObject.first_name = null
			this.userObject.middle_name = null
			this.userObject.last_name = null
			this.userObject.second_last_name = null
			this.userObject.address = null
			this.userObject.phone = null
			this.userObject.email = null
			this.userObject.password = null
			this.userObject.roles = []
			this.userObject.status = 1
			this.userObject.country_id = 178
			this.userObject.gender_id = null
			this.userObject.date_joined = null
			this.userObject.salary = null
			this.userObject.pay_day = null
			;(this.userObject.bank_accounts = [
				{
					bank_id: null,
					account_number: null,
					cci: null,
				},
			]),
				this.$refs.form.reset()
		},
	},
	watch: {
		rol(newVal, oldVal) {
			if (!this.$route.params.user_id || (this.$route.params.user_id && this.isAdmin)) {
				if (newVal == 1) {
					this.userObject.roles = []
					this.userObject.roles.push(1)
				} else {
					const oldRoles = this.userObject.roles.filter((it) => it !== 1)
					this.userObject.roles = []
					this.userObject.roles = oldRoles
				}
			}
		},
		"userObject.document_number"(val) {
			if (val) {
				if (this.userObject.document_number.length === 8 && this.userObject.personal_id === null) {
					this.getClientByDni()
				}
				if (this.userObject.document_number.length < 8) {
					this.userObject.first_name = null
					this.userObject.middle_name = null
					this.userObject.last_name = null
					this.userObject.second_last_name = null
				}
			}
		},
	},
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.card .card {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.disabled-btn {
	pointer-events: none;
	opacity: 0.5;
}

.error-span {
	color: red;
	font-size: 0.85rem;
	margin-top: 0.2rem;
}

.required-asterisk {
	color: red;
}

.form-group input {
	color: #6e6b7b !important;
}
</style>

<template>
	<div>
		<component :is="userData === undefined ? 'div' : 'b-card'">
			<!-- Alert: No item found -->
			<b-alert variant="danger" :show="userData === undefined">
				<h4 class="alert-heading">Error fetching user data</h4>
				<div class="alert-body">
					No user found with this user id. Check
					<b-link class="alert-link" :to="{ name: 'apps-users-list' }">User List</b-link>
					for other users.
				</div>
			</b-alert>

			<user-edit-component :user-data="userData" class="mt-2 pt-75" />
		</component>

		<div class="card p-2" v-if="this.$route.params.user_id && isAdmin">
			<AccessHistoryTable :idUser="this.$route.params.user_id" />
		</div>
	</div>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue"
import { ref, onUnmounted } from "@vue/composition-api"
import router from "@/router"
import store from "@/store"
import userStoreModule from "@/views/apps/user/userStoreModule.js"
import UserEditComponent from "@/views/amg/users/UserEditComponent.vue"
import UserEditTabInformation from "@/views/apps/user/users-edit/UserEditTabInformation.vue"
import UserEditTabSocial from "@/views/apps/user/users-edit/UserEditTabSocial.vue"
import AccessHistoryTable from "@/views/amg/users/accessHistory/component/AccessHistoryTable.vue"

export default {
	components: {
		BTab,
		BTabs,
		BCard,
		BAlert,
		BLink,
		AccessHistoryTable,
		UserEditComponent,
		UserEditTabInformation,
		UserEditTabSocial,
	},
	setup() {
		const userData = ref(null)
		const USER_APP_STORE_MODULE_NAME = "app-user"
		// Register module
		if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
			store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
		})
		// store.dispatch('app-user/fetchUser', { id: currentUser.id })
		//   .then(response => { userData.value = response.data })
		//   .catch(error => {
		//     if (error.response.status === 404) {
		//       userData.value = undefined
		//     }
		//   })

		return {
			userData,
		}
	},
}
</script>

<style></style>
